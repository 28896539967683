import React from "react";
import Helmet from "react-helmet";
import { Link } from "gatsby";
import SEO from "../../components/Seo";
import Layout from "../../components/Layout";
import MainContent from "../../components/MainContent";
import Breadcrumb from "../../components/Breadcrumb";
import InfoBox from "../../components/InfoBox";
import QuoteBox from "../../components/QuoteBox";
import CtaPrimary from "../../components/CtaPrimary";
import { currentYear } from "../../components/Helpers";
import ImgScreenshot from "../../components/ImgScreenshot.js";
import ImgContainerFixed from "../../components/ImgContainerFixed";
import H from "../../components/Headline";


const breadCrumbLevels = {
  Hem: "/",
  "Analysrådgivning": "/se/analysrådgivning",
  "GTM Tutorial": "/se/google-tag-manager-setup"
};

// hreflang data
const alternateLangs = [
  {
    hreflang: "en",
    href: "/en/google-tag-manager-setup"
  },
  {
    hreflang: "se",
    href: "/se/google-tag-manager-installation"
  },
  {
    hreflang: "da",
    href: "/da/google-tag-manager-opsætning"
  },
  {
    hreflang: "no",
    href: "/no/google-tag-manager-oppsett"
  },
  {
    hreflang: "nl",
    href: "/nl/google-tag-manager-setup"
  },
  {
    hreflang: "de",
    href: "/de/google-tag-manager-einrichten"
  },
  {
    hreflang: "x-default",
    href: "/en/google-tag-manager-setup"
  }
];

const FAQs = `
{
    "@context": "https://schema.org",
    "@type": "FAQPage",
    "mainEntity": [
      {
        "@type": "Question",
        "name": "Behöver jag Google Tag Manager?",
        "acceptedAnswer": {
        "@type": "Answer",
        "text": "Ja, eftersom din webbplats sannolikt vill köra Google Analytics och andra tredjepartsskript. Att ställa in allt detta är mycket enklare och snabbare med Google Tag Manager. Dessutom laddas din webbplats <a href='https://marketingland.com/audit-of-online-retailer-sites-shows-tag-management-systems-improve-load-times-reduce-errors-62173'>lite snabbare</a> också."
        }
      },
      {
      "@type": "Question",
      "name": "När ska jag använda Google Tag Manager?",
      "acceptedAnswer": {
      "@type": "Answer",
      "text": "Redan om du bara vill köra Google Analytics bör du använda Google Tag Manager. Att ställa in <a href='https://bluerivermountains.com/se/händelsespårning'>händelsespårning</a> och andra konfigurationer är mycket snabbare och enklare med GTM. Dessutom finns det många handledningar och guider online som förklarar hur man t.ex. <a href='http://bluerivermountains.com/se/google-analytics-setup'>ställer in Google Analytics</a>."
        }
      },
      {
        "@type": "Question",
        "name": "Hur använder jag Google Tag Manager med Google Analytics?",
        "acceptedAnswer": {
        "@type": "Answer",
        "text": "Lägg inte in Google Analytics-taggen i källkoden på din webbplats. Lägg bara till Google Tag Manager-taggen och implementera och <a href='https://bluerivermountains.com/se/google-analytics-setup'>ställ in Google Analytics</a> genom Google Tag Manager. Alla anpassade konfigurationer som <a href='https://bluerivermountains.com/se/händelsespårning'>händelsespårning</a> eller att lägga till andra skript, gör du i GTM."
        }
     },
     {
       "@type": "Question",
       "name": "Vad är skillnaden mellan Google Analytics och Google Tag Manager?",
       "acceptedAnswer": {
       "@type": "Answer",
       "text": "Google Analytics är biblioteket som samlar in data om besök på din webbplats. Google Tag Manager å andra sidan är ett bibliotek som körs på din webbplats för att implementera andra bibliotek eller verktyg som Google Analytics. Eftersom många av dessa verktyg har extra JavaScript-snippets för att skicka data till dem, ställer du in dem alla i GTM."
       }
    },
    {
      "@type": "Question",
      "name": "Var placerar jag Google Tag Manager-koden?",
      "acceptedAnswer": {
      "@type": "Answer",
      "text": "Den första delen av koden går så högt som möjligt i <head>-sektionen. Jag rekommenderar att implementera den inom <head> men efter alla <style> eller <script>-taggar som är viktiga för att rendera sidan - eftersom vi inte vill försena dem. Den andra delen av GTM-kodsnutten är att möjliggöra en grundläggande funktionalitet på sidor med JavaScript avstängt. Den går så högt som möjligt i <body>-elementet. Logiken bakom placeringen av båda taggarna är att säkerställa tidig laddning av GTM. Det gör att du kan ingripa och kontrollera delar av sidladdningsprocessen så tidigt som möjligt."
      }
   },
   {
     "@type": "Question",
     "name": "Inkluderar Google Tag Manager Google Analytics?",
     "acceptedAnswer": {
     "@type": "Answer",
     "text": "Nej, men Google Tag Manager gör det möjligt för dig att implementera Google Analytics på några sekunder med bara några klick. Det enda du behöver är ditt Google Analytics-spårnings-ID. Generellt sett behöver du dock inte använda Google Analytics med Google Tag Manager. De är oberoende av varandra."
     }
  }
  ]
}
`;

const GTMsetup = props => (
  <Layout location={props.location} alternateLangs={alternateLangs}>

    <React.Fragment>
      <SEO
        title={`Installationsguide för Google Tag Manager: Setup Guide ${currentYear}`}
        description="Lär dig hur du använder GTM för att ställa in Google Analytics, händelsespårning, remarketing-taggar och ett data layer, samt bästa praxis för att spåra nedladdningar och externa länkar."
        lang="se"
        canonical="/se/google-tag-manager-setup"
        image="google-tag-manager-tutorial-hero.png"
        alternateLangs={alternateLangs}
      />
      <MainContent article>
        <ImgScreenshot
          src="gtm-setup/google-tag-manager-tutorial-hero.png"
          alt="användargränssnitt för Google Tag Manager"
        />
        <Breadcrumb breadCrumbLevels={breadCrumbLevels} />
        <H as="h1">Google Tag Manager Tutorial</H>

        <p>Som en <Link to="/se/google-tag-manager-konsult">Google Tag Manager-konsult</Link> har jag satt upp GTM på <b>100+ kunders webbplatser</b>. Denna Google Tag Manager-tutorial är där jag lär ut processen jag har förfinat genom åren, steg för steg, med exempel och videor för dig att lära dig.</p>
        <p>Längre ner kan du <Link to="/se/google-tag-manager-setup#download-gtm-config-container-file">ladda ner en GTM-konfigurationsfil</Link> med alla följande bästa metoder att importera till din container.</p>
        <p>Om du inte kan vänta, hoppa direkt in i <Link to="/se/google-tag-manager-setup#install-google-tag-manager-on-your-website">installationen</Link> eller lär dig <Link to="/se/google-tag-manager-setup#how-to-set-up-google-tag-manager">hur man ställer in Google Tag Manager</Link>. Men om du är en <b>nybörjare</b> är det viktigt att först förstå <em>hur</em> man använder ett <Link to="/se/tagghantering">tagghanteringssystem</Link> tillsammans med andra verktyg.</p>
        <p>Så fortsätt läsa nedan först.</p>
        <H as="h2">Hur använder man Google Tag Manager?</H>
        <p>Jag antar att du redan vet <Link to="/se/vad-ar-google-tag-manager">vad Google Tag Manager är</Link>. Så låt oss prata om hur GTM fungerar och hur man använder det.</p>
        <p>Idealiskt vill du bara ha <b>en</b> tredjepartstagg i källkoden på din webbplats eller web app.</p>
        <QuoteBox
          quote={`Den enda tredjepartstaggen på din webbplats bör vara Google Tag Manager-kodsnutten.`}
        />
        <p>Alla andra taggar implementeras sedan genom själva tagghanteraren. Andra marknadsförings- och spårningstaggar är t.ex. Google Analytics (GA), Facebook, Twitter, LinkedIn, AdWords, DoubleClick och gud vet vad.</p>
        <p>Den främsta anledningen är <Link to="/se/fordelar-med-google-tag-manager">fördelarna med Google Tag Manager</Link>:</p>
        <ul>
          <li><b>enklare & snabbare</b> implementering av marknadsföringstaggar</li>
          <li>skalbarhet på varje sida och över flera domäner</li>
          <li><b>inbyggda triggers</b> för formulärinlämningar, rullningsspårning & videospårning</li>
          <li>hantera användare med flera GTM-konton</li>
          <li>en lite <a rel="noopener" target="_blank" href="https://marketingland.com/audit-of-online-retailer-sites-shows-tag-management-systems-improve-load-times-reduce-errors-62173">snabbare sidladdningshastighet</a></li>
        </ul><br />
        <p>På grund av dessa fördelar använder redan <a target="_blank" href="https://w3techs.com/technologies/overview/tag_manager">30% av alla webbplatser på internet en tagghanterare</a>. Och bland dem har Google Tag Manager en marknadsandel på <a target="_blank" rel="noopener" href="https://trends.builtwith.com/analytics/tag-management/traffic/Entire-Internet">94%</a>.</p>
        <p>Så, om du inte har en god anledning att inte lägga till en tagg i GTM, som en allmän tumregel, <b>lägg till alla taggar i GTM
          container</b>.</p>

        <QuoteBox
          quote={`Använd GTM som ett anslutande lager mellan din webbplats och tredjepartstaggar.`}
        />
        <p>Använd GTM som ett <b>mellanlager</b> mellan din webbplats och tredjepartstjänster. Utan det är din webbplats och tredjepartstaggar inte i direkt anslutning. Dessa tjänster är mestadels JavaScript-bibliotek för marknadsförings- eller spårningsverktyg som implementeras med en tagg. Men alla andra taggar gäller också.</p>
        <p>Det enda undantaget till regeln gäller när du gör konverteringsoptimering med split-testing-verktyg.</p>
        <p>Eftersom under konverteringsoptimering kommer A/B-tester att ladda olika varianter av en sida. Så besökaren kan se hur innehållet omrenderas för en bråkdel av en sekund.</p>
        <p>För att undvika CSS-flimmer och säkerställa att varianttester laddas snabbt kan en split-testing-tagg också gå direkt in i källkoden.</p>
        <p>Nu när vi har detta ur vägen, låt oss titta på implementeringen.</p>
        <H as="h2">Installera Google Tag Manager på din webbplats</H>
        <p>Låt oss börja Google Tag Manager-tutorial genom att visa dig var du får Google Tag Manager-kodsnutten och sedan var du ska installera den på webbplatsen. Du kan logga in bara genom att använda ditt vanliga Google-konto.</p>
        <ol>
          <li><H as="h3">Skapa ett Google Tag Manager-konto</H>
            För att installera GTM måste du först gå till <a rel="noopener" target="_blank"
              href="https://tagmanager.google.com/">den officiella webbplatsen</a> och skapa ett nytt Google Tag Manager-konto.<br />
            <ImgScreenshot
              src="google-analytics-setup/google-tag-manager-create-account.png"
              alt="Skapa Google Tag Manager-konto"
              caption="Först, skapa ett Google Tag Manager-konto och välj ett container-namn, som ditt webbplatsnamn, och få sedan kodsnutten."
              className="article-img"
            />
          </li>
          <li><H as="h3">Skapa en webbegendom</H>
            Välj <b>Web</b> egendom för att få en kod för en webbplats eller web app.<br />
            <ImgScreenshot
              src="google-analytics-setup/google-analytics-setup-via-google-tag-manager-container-creation.png"
              alt="GTM-container-namn och webbplatsval"
              className="article-img"
              caption="Det finns flera typer av containrar tillgängliga i ett GTM-konto. För webbplatser, välj web. Observera att det finns andra typer av tagghanterarcontainrar för AMP-sidor, iOS och Android också."
            />
          </li>
          <li><H as="h3">Implementera Google Tag Manager-koden</H><ul>
            Därefter kommer du att visas Google Tag Manager-koden att implementera på din webbplats.<br />
            <ImgScreenshot
              src="google-analytics-setup/google-tag-manager-code-snippet.png"
              alt="Google Tag Manager-kodsnutt"
              caption="Detta är Google Tag Manager-container-taggen. Den har två delar. Instruktionerna om hur man implementerar skript-taggarna är skrivna ovanför varje del."
              className="article-img"
            />
            <li>Ta den <b>första delen</b> av <Link to="/se/wiki-analytics/behållar-tag">containertaggen</Link> och placera den så högt som möjligt i <b>head</b> taggen på varje sida på din webbplats. Detta säkerställer att du kan köra taggar tidigt under sidladdningar.</li>
            <li>Den <b>andra delen</b> är en iframe som körs i webbläsare med JavaScript inaktiverat. Installera taggen så högt som möjligt i <b>body</b> taggen på varje sida på din webbplats.<br />
              <ImgScreenshot
                src="data-layer/positionierung-data-layer-gtm.png"
                alt="dataLayer är placerad före TMS-taggen i källkoden"
                caption={`Den första taggen i <head> är ett data layer. Oroa dig inte om du ännu inte vet vad det är (första pilen). Nästa är den första delen av GTM-taggen (andra pilen). Slutligen implementeras den andra delen av GTM-taggen högt upp i <body>-elementet. eller JavaScript-kod kan implementeras däremellan, men ett data layer implementeras alltid före GTM-taggen och <noscript> GTM-taggen är alltid sist.`}
                className="article-img"
              />
            </li>
          </ul>
          </li>
        </ol>

        <p>Det här är den vanliga metoden för att implementera GTM.</p>
        <p>Använder du ett populärt content management system? Om ja, kan du också använda ett <b>plugin</b> som tar hand om installationen av Google Tag Manager.</p>
        <p>Det sagt:</p>
        <InfoBox
          type="info"
          headline="Om ditt CMS också erbjuder ett plugin för att installera andra taggar"
          h="false"
          dots="true"
          tweetcopy="Om ditt CMS också erbjuder ett plugin för att installera andra taggar, använd inte ytterligare ett plugin för att installera Google Analytics, Facebook eller Google Ads. Använd istället GTM för att installera dessa taggar."
        >
          <p>Använd inte ytterligare ett plugin för att installera Google Analytics, Facebook eller Google Ads.</p>
          <p>Använd istället <b>GTM för att installera dessa taggar</b>.</p><br />
          <ol>
            <li>Det resulterar i snabbare sidladdning</li>
            <li>Det ger dig fler alternativ att konfigurera taggen</li>
          </ol>
        </InfoBox>
        <p>GTM-användargränssnittet får också regelbundet uppdateringar med nya funktioner, så du är nästan alltid bättre av att implementera andra marknadsföringstaggar direkt med det än med en annan integration.</p><p>Plus, vinsterna i laddningstid är bra för din <Link to="/se/wiki-analytics/avvisningsfrekvens">avvisningsfrekvens</Link> och hjälper SEO.</p>
        <H as="h3">Använd ett plugin för att implementera GTM</H>
        <p>Nedan en lista över de vanligaste content management-systemen och deras plugins för att installera Google Tag Manager.</p>
        <H as="h4">WordPress</H>
        <p>Det finns två WordPress-plugins för att implementera GTM som jag skulle använda. <b>Först</b> finns det det klassiska alternativet kallat <a rel="noopener" target="_blank" href="https://wordpress.org/plugins/duracelltomi-google-tag-manager/">Google Tag Manager för WordPress</a>.<br />
          Det <b>andra</b> alternativet är <a rel="noopener" target="_blank" href="https://wordpress.org/plugins/google-site-kit/">Site Kit by Google</a>. Det tillåter främst att lägga till en instrumentpanel till din WordPress-backend som visar information från ditt Google Analytics-konto och Google Search Console-data - vilket är ganska häftigt. Och det låter dig också installera GTM.</p>
        <H as="h4">Shopify</H>
        <p>För Shopify finns det ett <b>gratis</b> plugin för GTM-installation kreativt kallat <em><a rel="noopener" target="_blank" href="https://apps.shopify.com/trafficguard?surface_detail=google+tag+manager&amp;surface_inter_position=1&amp;surface_intra_position=6&amp;surface_type=search">Google Tag Manager Installer</a></em>.</p>
        <H as="h4">Squarespace</H>
        <p>För Squarespace finns det ingen GTM-förlängning eller plugin. Men du kan lägga till GTM-taggen manuellt genom att gå till <b>sidopanel</b> &gt; <b>inställningar</b> &gt; <b>avancerat</b> &gt; <b>kodinjektion</b>.</p>

        <ImgScreenshot
          src="gtm-setup/google-tag-manager-installation-in-squarespace.png"
          alt="Squarespaces navigeringsmeny för kodinjektion"
          caption={`I Squarespace kan du implementera GTM under Inställningar > Avancerat > Kodinjektion`}
          className="article-img"
        />

        <p>Sedan klistrar du in GTM-taggen i formulärfälten så här:</p>

        <ImgScreenshot
          src="gtm-setup/gtm-code-injection-in-squarespace.png"
          alt="Injicera GTM-kodsnippets i Squarespace"
          caption={`Placera första delen av GTM-koden i header-fältet. Andra delen går in i footer-fältet.`}
          className="article-img"
        />

        <H as="h4">Wix</H>
        <p>Besök huvudmenyn för din Wix-webbplats på vänstra sidopanelen. Därifrån besöker du <b>Marknadsföring &amp; SEO</b> och klickar sedan på <b>Marknadsintegreringar</b> längre ner i sidopanelen.<br />
          Där hittar du flera Wix-integreringar för Google Analytics, Facebook-pixeln och även en Wix-förlängning för att implementera Google Tag Manager.</p>

        <ImgScreenshot
          src="gtm-setup/google-tag-manager-setup-in-wix.png"
          alt="Wix Marknadsföringsintegration för Google Tag Manager"
          caption={`I Wix använder du marknadsintegrationen för Google Tag Manager.`}
          className="article-img"
        />
        <p>Klicka på anslut och installera Google Tag Manager.</p>

        <H as="h2">Hur kontrollerar man om GTM fungerar?</H>
        <InfoBox
          type="info"
          headline="När du först loggar in på GTM"
          h="false"
          dots="true"
          tweetcopy="När du först loggar in på GTM...Gå till submit-knappen och publicera en tom container. Annars, när du testar om GTM fungerar, kommer skriptet att returnera ett 400-svarsfel och du kommer att spendera timmar på att felsöka varför. 😭"
        >
          <p>Gå till submit-knappen och publicera en <b>tom container</b>.</p>
          <p>Annars, när du testar om GTM fungerar, kommer skriptet att returnera ett <b>400-svarsfel</b> och du kommer att spendera timmar på att felsöka varför. 😭 <br /><br />Det är en klassiker 😉</p>
        </InfoBox>
        <p>Efter att du har implementerat GTM-skriptet och <b>publicerat en container</b>version (viktigt), kan du testa om Google Tag Manager fungerar genom att göra någon av dessa kontroller:</p>
        <ol>
          <li><H as="h3">Förhandsgranska och felsökningsläge</H>Logga in på ditt GTM-konto och klicka på <b>förhandsgranska</b>. Öppna sedan en ny flik i webbläsaren och besök din webbplats. GTM-felsökningsfönstret bör öppnas längst ner i fönstret om GTM fungerar korrekt.<br />
            <ImgScreenshot
              src="event-tracking/google-tag-manager-event-trigger-preview.png"
              alt="Aktivera GTM felsökningsläge"
              caption={`Aktivera GTM felsökningsläge för att kontrollera om GTM fungerar korrekt.`}
              className="article-img"
            />

          </li>
          <li><H as="h3">Chrome Developer Tools</H><b>Öppna Chrome Developer Tools</b> genom att högerklicka på valfri sida på din webbplats och välja <em>inspektera</em> (Alternativt F12 på Windows och Shift+CTRL+J på Mac).<br />
            Gå sedan till fliken <b>network</b> och <b>ladda om webbsidan samtidigt</b> (F5 på Windows och CMD+Shift+R på Mac). Fliken network fylls med alla nätverksförfrågningar som krävs för att ladda sidan.<br />
            I filterfältet i övre vänstra hörnet skriver du <em>gtm.js</em> för att hitta förfrågan för din JavaScript-kod och verifiera att den har en <b>statuskod på 200</b>.<br /><br />
            Låt mig visa dig:<br /><br />
            <video
              loading="lazy"
              title={`Kontrollera om Google Tag Manager fungerar`}
              autoplay
              muted
              playsinline
              loop
              controls
              css="max-width:100%;border:3px solid lightgrey;"
            >
              <source src="/video/check-if-gtm-is-working.mp4" type="video/mp4" />
            </video>
            <br />
            <b>Om du inte har en statuskod på 200, kanske du glömde att skicka in och publicera en container först i GTM?</b></li>
          <li><H as="h3">Google Tag Assistant</H>Installera <a rel="noopener" target="_blank" href="https://chrome.google.com/webstore/detail/tag-assistant-by-google/kejbdjndbnbjgmefkgdddjlbokphdefk">Google Tag Assistant</a> Chrome-tillägget och starta det på din webbplats. Det bör ange en GTM-container-ID.<br />

            <ImgContainerFixed width="452px">
              <ImgScreenshot
                src="gtm-setup/gtm-validation-with-tag-inspector.png"
                alt="Google Tag-inspektören validerar att GTM laddas korrekt"
                caption={`Du kan också använda Chrome-tillägget Google Tag Assistant för att säkerställa att Google Tag Manager fungerar korrekt.`}
                className="article-img"
              />
            </ImgContainerFixed>
          </li>
        </ol>
        <H as="h2">Hur ställer man in Google Tag Manager?</H>
        <p>När du ställer in Google Tag Manager kan du göra många avancerade konfigurationer. Så <b><em>hur</em></b> du ställer in GTM beror på vilka andra verktyg du planerar att använda i ditt <Link to="/se/tagghantering">tagghanteringssystem</Link>.</p>
        <p>Därför har jag samlat alla relevanta tutorials som täcker allt du kan tänkas vilja ställa in i ditt GTM-konto - med exempel. Följ helt enkelt denna Google Tag Manager-guide och skapa därmed en solid grund för tagghantering på din webbplats.</p>
        <p>Endast tutorialen om att implementera ett data layer kräver kodningskunskaper eller eventuellt webbprogrammerare.</p>
        <p><b>Notera</b>: I denna Google Tag Manager-tutorial kommer vi att använda GTM genom att <b>manuellt</b> ställa in nya taggar och triggers för varje händelse. Tillvägagångssättet är inte super skalbart, men det är tillräckligt snabbt och enkelt, samtidigt som det uppfyller de flesta spårningsambitioner och ändå är tillämpligt på andra avancerade uppsättningar.</p>
        <p>Större webbplatser och e-handelsbutiker kräver en <b>skalbar tagghanteringslösning</b>. Därför implementeras ett <Link to="/se/data-lager">data lager</Link> som den centrala delen för att spåra händelser. Med en sådan uppsättning kan du använda event handlers istället för att ställa in taggar, triggers och variabler för varje händelse.</p>
        <ol>
          <li>
            <H as="h3">Ställ in Google Analytics-spårning</H>
            <p>Detta är det första steget för alla. Lär dig i denna guide hur du implementerar solid Google Analytics-spårning, med mål, trattar och dina egna besök exkluderade från trafiken. Plus fler bästa praxis.</p>

            <Link to="/se/google-analytics-setup"><CtaPrimary color="purple" arrow="true">Ställ in Google Analytics</CtaPrimary></Link>

          </li>
          <li>
            <H as="h3">Ställ in händelsespårning</H>
            <p>När den grundläggande spårningsimplementeringen fungerar som den ska, kommer du också vilja lära dig att spåra användarengagemang. Hur ofta, till exempel, skickar en besökare formulär och klickar på en skicka-knapp eller ett annat HTML-element? Min <Link to="/se/händelsespårning">händelsespårningsguide</Link> förklarar exakt detta för ett <b>knappklick</b> och du kan använda samma metod för alla andra klickspårningar.</p>
            <Link to="/se/händelsespårning"><CtaPrimary color="purple" arrow="true">Ställ in händelsespårning</CtaPrimary></Link>
          </li>
          <li>
            <H as="h3">Lägg till remarketing-taggar</H>
            <p>Det vanligaste användningsfallet för GTM <em>efter</em> att ha installerat GA är att lägga till remarketing-taggar på en webbplats. De utgör trots allt majoriteten av tredje parts marknadsföringstaggar och spårningskoder som skräpar ner vår kodbas.<br />
              Därför implementerar vi dem genom vårt GTM-konto för att hålla kodbasen ren från marknadsförings- och analystaggar samtidigt som vi drar nytta av <Link to="/se/fordelar-med-google-tag-manager">fördelarna med Google Tag Manager</Link>.</p>
            <p>Låt oss lära oss hur man lägger till de vanligaste remarketing-taggarna inom digital marknadsföring, <b>Facebook-pixeln</b> och <b>Google Ads remarketing-taggen</b>.</p>
            <H as="h4">Lägg till Facebook-pixeln</H>
            <ul>
              <li>
                Först behöver du din <b>Facebook-pixel-ID</b>. Besök Facebooks <a rel="noopener" target="_blank" href="https://www.facebook.com/events_manager2/list/pixel/">Events Manager</a> och klicka på <b>grön plussymbol</b> för att skapa Facebook-pixeln. Därefter kommer ditt pixel-ID att listas på skärmen.
                <ImgScreenshot
                  src="gtm-setup/get-fb-pixel.png"
                  alt="Event Manager i Facebook visar pixel-ID"
                  caption={`Hitta ditt Facebook-pixel-ID i Event Manager.`}
                  className="article-img"
                />
              </li>
              <li>
                Skapa sedan en <b>ny tagg</b> via Google Tag Manager, kalla den till exempel <em>Facebook - Page view</em> och besök galleriet för <b>taggmallar</b>.
              </li>
              <li>
                Sök efter <em>Facebook</em> och välj Facebook-pixeln.<br />

                <ImgScreenshot
                  src="gtm-setup/fb-pixel-template-gallery-gtm.png"
                  alt="GTM:s mallgalleri visar Facebook-pixeln"
                  caption={`Implementera Facebook-pixeln från GTM:s taggmallar.`}
                  className="article-img"
                />
              </li>
              <li>
                Lägg till ditt <b>Facebook-pixel-ID</b> och klicka på <b>spara</b>.
              </li>
              <li>
                Ställ in att taggen ska aktiveras på <b>alla sidor</b>.
                <br /><br />
                <video
                  loading="lazy"
                  autoplay
                  muted
                  playsinline
                  loop
                  controls
                  title={`Installera Facebook-pixeln i Google Tag Manager`}
                  css="max-width:100%;border:3px solid lightgrey;"
                >
                  <source src="/video/add-facebook-pixel-with-gtm.mp4" type="video/mp4" />
                </video>
              </li>
              <li>
                Därefter klickar du på <b>submit</b> i övre högra hörnet för att aktivera taggen.
              </li>
              </ul>
              <H as="h4">Lägg till Google Ads remarketing</H>
              <ul>
                <li><b>Först</b>, hämta ditt <b>Google Ads-konverterings-ID</b> för din målgrupp från <b>Shared Library &gt; Audiences</b>. Användargränssnittet ändrades nyligen, men leta efter <b>tag details</b> eller <b>setup tag</b> för att hitta informationen nedan.
                  <br />
                  <ImgContainerFixed width="432px">
                    <ImgScreenshot
                      src="gtm-setup/google-ads-conversion-id.png"
                      alt="Kodsnuttar för Google-konverterings-ID och konverteringsetikett"
                      caption={`Ta ditt konverterings-ID och konverteringsetikett från taggdetaljerna i din målgrupp.`}
                      className="article-img"
                    />
                  </ImgContainerFixed>
                </li>
                <li>Gå sedan till <b>taggar</b> i GTM och klicka på <b>ny</b> för att lägga till vår nya marknadsföringstagg.</li>
                <li>Ge den ett namn som <em>Google Ads - Page view</em>.</li>
                <li>Välj som variabeltyp <b>Google Ads Remarketing</b>.</li>
                <li>Ange ditt <b>konverterings-ID</b> och eventuellt <b>konverteringsetikett</b>. Låt sedan taggen aktiveras på <b>alla sidor</b> och klicka på <b>spara</b>.<br /><br />Låt mig visa dig i denna video:
                  <video
                    loading="lazy"
                    autoplay
                    muted
                    playsinline
                    loop
                    controls
                    title={`Installera Google Ads page view med GTM`}
                    css="max-width:100%;border:3px solid lightgrey;"
                  >
                    <source src="/video/google-ads-page-view.mp4" type="video/mp4" />
                  </video>
                </li>
                <li>Klicka på <b>submit</b> i övre högra hörnet för att aktivera marknadsföringstaggen.</li>
              </ul>
            </li>
            <li>
              <H as="h3">Implementera ett data layer</H>
              <p>Du kommer vilja implementera ett data layer om du ställer in taggar regelbundet och det tar för lång tid och är helt enkelt för arbetskrävande.</p>
              <p>En annan fördel är att du kan använda informationen från din databas för att utlösa triggers eller skicka den som händelsedata. Andra externa datakällor kan också integreras. Webbplatser som behöver e-handelsuppföljning faller vanligtvis i denna kategori.</p>
              <p>Min artikel om data layer förklarar implementeringen, data layer-variabler och hur man konfigurerar anpassad spårning på ett skalbart sätt, vilket är ett typiskt användningsfall för <b>stora e-handelsbutiker</b> som behöver avancerad e-handelsuppföljning.
              </p>

              <Link to="/se/data-lager"><CtaPrimary color="purple" arrow="true">Implementera data layer</CtaPrimary></Link>
            </li>
          </ol>
          <H as="h2">Bästa praxis för varje GTM-installation</H>
          <p>Varje gång jag ställer in Google Tag Manager kommer installationen med några konfigurationer som jag lägger till varje gång. Dessa bästa praxis är tillämpliga och hjälpsamma för de flesta företag och bör inte saknas i någon GTM-tutorial. Se listan nedan och välj de som är användbara för dig.</p>
          <p>Längre ner kan du <Link to="/se/google-tag-manager-setup#download-gtm-config-container-file">ladda ner en GTM-installationskonfiguration</Link> med alla dessa bästa praxis för att importera till din egen container.</p>
          <H as="h3">Spåra utgående länkar</H>
          <p>Att spåra klick på utgående länkar innebär att spåra alla klick på externa länkar som leder från din webbplats till andra webbplatser.
             Att spåra externa länkar är en bästa praxis som låter dig veta till vilka webbplatser du skickar besök och hjälper dig att verifiera dina besökares intresse.<br />För att implementera spårning av externa länkar finns det <b>tre steg</b>:</p>
          <ol>
            <li>
              <H as="h4">Skapa en anpassad händelsevariabel i GTM</H>
              <ul>
                <li>Besök <b>användardefinierade variabler</b> från GTM-översikten och klicka på <b>ny &gt; auto-event variabel</b>.</li>
                <li>Den ska utvärdera länken URL för ett klickat element och returnera <code>true</code> om <code>href</code> attributet håller en utgående länk - eller <code>false</code> om länken är intern.</li>
                <li>Ge den nya variabeln ett namn som <em>Linkisoutbound</em> och välj <em>variabeltyp</em> till <b>Element URL</b> och <em>Komponenttyp</em> <b>is outbound</b>. Klicka sedan på <b>spara</b>.<br />

                  <ImgScreenshot
                    src="gtm-setup/auto-event-variable-for-outbound-links.png"
                    alt="Inställningar för utgående länk auto-event variabel"
                    caption="Auto-event variabeln i Google Tag Manager kommer att hålla ett värde av TRUE när adressen för den klickade länken är utgående och pekar på en extern sida."
                    className="article-img"
                  />

                </li>
              </ul>
            </li>
            <li>
              <H as="h4">Skapa en ny trigger för utgående länkar</H>
              <ul>
                <li>I sidnavigeringen under triggers, klicka på ny och skapa en ny trigger i GTM.</li>
                <li>Välj <em>trigger-typ</em> till <b>just links</b> och namnge den t.ex. <em>Event - Outbound Link</em>.</li>
                <li>Ställ in triggern till <em>fire on some link clicks</em> och välj din nya auto-event variabel <b>Linkisoutbound</b> så att den är lika med <code>true</code>:<br />

                  <ImgScreenshot
                    src="gtm-setup/outbound-links-trigger-config.png"
                    alt="Triggerkonfiguration för spårning av utgående länkar"
                    caption={`Triggerinställningar för spårning av utgående länkar.`}
                    className="article-img"
                  />

                </li>
              </ul>
            </li>
            <li>
              <H as="h4">Skapa en tagg för GA-händelsen</H>
              <ul>
                <li>Besök <b>variabler</b> och klicka på <b>konfigurera</b> för <em>inbyggda variabler</em>. Aktivera sedan <b>click URL</b>-variabeln. Stäng igen och gå till sektionen för taggar.</li>
                <li>Skapa en ny <b>Google Analytics: Universal Analytics</b>-tagg med namnet <em>Event - Outbound link</em> och sätt <em>track type</em> till <b>event</b>.</li>
                <li>För <b>kategori</b> lägger du bara till <code>Outbound link click</code>, <b>action</b> är <code>click</code> och <b>label</b> ska referera till vår nyligen aktiverade GTM-variabel. För att göra det använder vi dubbla måsvingar <code>{`{{ Click URL }}`}</code>.</li>
                <li>Händelsevärdet <b>value</b> kan sättas till <code>0</code>, om du inte kan tilldela ett nominellt värde för en extern länk (t.ex. för affiliatelänkar).</li>
                <li>Inställningen <b>non-interaction hit</b> ska förbli inställd på <code>false</code>, eftersom ett utgående klick faktiskt är en användarinteraktion.</li>
                <li><b>Google Analytics-inställningsvariabeln</b> bör vara samma användardefinierade variabel som i din tagg för sidvisningar.<br />

                  <ImgScreenshot
                    src="gtm-setup/google-analytics-event-tag-external-link-clicks.png"
                    alt="Inställningar för Google Analytics-händelsetagg för att spåra utgående länkar"
                    caption={`Inställningar för händelsetaggen för att spåra utgående länkar.`}
                    className="article-img"
                  />

                </li>
                <li>Som sista steg, scrolla ner till <em>triggering</em>-sektionen och <b>välj den nyss skapade triggern</b> <em>Event - Outbound Link</em>. Klicka sedan på <b>spara</b> och <b>submit</b> för att skicka dina container-ändringar från huvudöversikten.</li>
              </ul>
            </li>
          </ol>
          <H as="h3">Spåra klick på e-postadresser</H>
          <p>Klick på e-postadresser är en användbar mätning som tenderar att korrelera med telefonsamtal eller besök till en fysisk butik.<br />För att ställa in Google Analytics-spårning för e-postklick, följ stegen i nedanstående tutorial:</p>
          <ol>
            <li>
              <H as="h4">Lägg till en ny trigger för e-postadressklick</H>
              <ul>
                <li>Aktivera <b>click URL</b>-variabeln. Du kan hoppa över detta steg om du redan har gjort det i den tidigare inställningen. Annars: Gå till sektionen för <b>variabler</b> och <b>konfigurera</b> de <em>inbyggda variablerna</em> för att lägga till <b>click URL</b>-variabeln. Stäng därefter panelen och gå till trigger-sektionen.</li>
                <li>Under <b>triggers</b>, klicka på <em>ny</em> och skapa en ny trigger med namnet t.ex. <em>Event - Mail click</em>, sätt typen till <b>click - just links</b> och låt den endast trigga på <em>some link clicks</em>.</li>
                <li>Ställ in villkoren för dessa länkklick så att dropdown-menyerna är <b>Click URL</b>, sedan <b>contains</b> och slutligen lägg till strängen <code>mailto:</code><br /></li>

                <ImgScreenshot
                  src="gtm-setup/email-click-trigger-settings-in-gtm.png"
                  alt="Triggerkonfiguration för att spåra klick på e-postadresser"
                  caption={`Triggerkonfiguration för att spåra klick på e-postadresser.`}
                  className="article-img"
                />
              </ul>
            </li>
          <li>
            <H as="h4">Ställ in GA-händelsetaggen</H>
            <ul>
              <li>Skapa en ny tagg, välj taggtyp <b>Google Analytics: Universal Analytics</b> och ge den namnet <em>Event - Email link</em>. Välj sedan <b>event</b> som <em>track type</em>.</li>
              <li><b>Kategorin</b> kan sättas till en statisk sträng <code>Email link click</code>, <b>action</b> är <code>click</code> och <b>label</b> ska referera till <code>{`{{ Click URL }}`}</code>.</li>
              <li>Händelsevärdet <b>value</b> kan återigen vara <code>0</code> om du inte kan tilldela ett meningsfullt värde. Om du till exempel visste att var 10:e e-postkontakt leder till en försäljning på 100$, kan du tilldela 10$ som genomsnittligt händelsevärde.</li>
              <li><b>Non-interaction hit</b> bör vara inställt på <code>false</code>, eftersom interaktionen är frivillig och därför aktiv.</li>
              <li><b>Google Analytics-inställningsvariabeln</b> ska vara densamma som för din sidvisningstagg eller utgående länktagg.<br />

                <ImgScreenshot
                  src="gtm-setup/google-analytics-event-tag-emails.png"
                  alt="Konfiguration GA e-postklickhändelse"
                  caption={`Konfigurationsinställningar för GA-händelsetaggen för e-post.`}
                  className="article-img"
                />

              </li>
              <li>Det sista steget är att gå ner till <em>triggering</em> och <b>välj den trigger vi just skapade</b> <em>Event - Mail click</em>. Därefter <b>spara</b> och gå till översikten för att <b>submit</b> dina ändringar.</li>
            </ul>
          </li>
        </ol>
        <H as="h3">Spåra klick på telefonnummer</H>
        <p>Att spåra klick på telefonnummer är främst användbart på mobila enheter. Genom att klicka på en telefonnummerlänk initieras direkt ett telefonsamtal. På datorer initierar musklik ofta ingenting. Men precis som för att spåra klick på e-postadresser, är det en bra mätning för att bekräfta kontaktfrekvenser överlag, eftersom det är korrelerat med andra metoder för att kontakta ett företag.</p>
        <p>Lär dig att konfigurera GTM för att spåra telefonnummerklick genom att följa stegen nedan.</p>
        <ol>
          <li>
            <H as="h4">Skapa en trigger för klick på telefonnummer</H>
            <ul>
              <li>Som i de andra guiderna ovan behöver vi aktivera variabeln <b>click URL</b> från <em>inbyggda variabler</em>.</li>
              <li>Skapa sedan en ny trigger <em>Event - Phone click</em> av typen <b>click - just links</b> som endast triggar på <em>some link clicks</em>.</li>
              <li>Fyll i trigger-villkoret enligt följande: <b>Click URL</b> - <b>contains</b> och sedan strängen <code>tel:</code>. Spara sedan.<br />

                <ImgScreenshot
                  src="gtm-setup/phone-number-trigger-in-gtm.png"
                  alt="GTM-inställningar för en telefonnummertrigger"
                  caption={`Inställningar för en trigger som aktiveras vid klick på telefonnummer.`}
                  className="article-img"
                />

              </li>
            </ul>
          </li>
          <li>
            <H as="h4">Skapa en telefonnummerhändelsetagg</H>
            <ul>
              <li>Lägg till ännu en <b>Universal Analytics-tagg</b> av typen <b>event</b> och kalla den <em>Event - Phone clicks</em></li>
              <li><b>Kategorin</b> är den statiska strängen <code>Phone number click</code>, <b>action</b> är <code>click</code> och som <b>label</b> använder vi återigen den inbyggda variabeln med dubbla måsvingar, <code>{`{{ Click URL }}`}</code>.</li>
              <li>Om du inte kan tilldela ett genomsnittligt värde till ett leda samtal, håll det på <code>0</code>. Annars, lägg till ett nummer för det genomsnittliga värdet.</li>
              <li><b>Non-interaction hit</b> bör ställas in på <code>false</code>, eftersom detta är en aktiv, frivillig interaktion. Välj <b>inställningsvariabeln</b> från dina användardefinierade variabler, som du också använder för sidvisningar.



              <ImgScreenshot
              src="gtm-setup/ga-event-phone-number.png"
              alt="Konfiguration GA telefonnummer klickhändelse"
              caption={`Konfiguration av GA telefonnummer klickhändelse.`}
              className="article-img"
              />
            </li>
            <li>Nu kopplar du den nyskapade triggern till denna GA-händelsetagg genom att gå ner till triggering-området och välja den nya triggern <em>Event - Phone click</em>. Sista steget är att <b>spara</b> och klicka på submit.</li>
            </ul>
          </li>
        </ol>
        <H as="h3">Spåra nedladdningar</H>
        <p>Att spåra hur ofta besökare laddar ner ditt material är en bra indikator på engagemang. Sådana nedladdningar kan vara t.ex.
          e-böcker, Excel-ark, bilder, videor eller musik.</p><p>Att spåra nedladdningar fungerar bra för att skilja mellan besöksgrupper som inte var intresserade av sidans innehåll och besökare som faktiskt var intresserade och laddade ner det du erbjöd.</p>
        <p>Följ denna tutorial för att lära dig att ställa in nedladdningsspårning:</p>
        <ol>
          <li>
            <H as="h4">Konfigurera en GTM-trigger för nedladdningsklick</H>
            <ul>
              <li>Skapa en ny trigger kallad <em>Event - Downloads</em>, välj som <em>trigger-typ</em> <b>click - just links</b> som endast utlöses på <b>some link clicks</b>.</li>
              <li>För villkoret ställ in den inbyggda variabeln <b>Click URL</b> till <b>end with</b> strängen <code>.pdf</code> för att spåra t.ex. PDF-nedladdningar. Ändra tilläggssträngen till den filändelse du erbjuder för nedladdning.
              </li>
              <li>Om du vill spåra <b>flera filändelser</b> för nedladdning kan vi använda ett reguljärt uttryck. Ändra villkoret till <b>Click URL</b> och sedan <b>matches RegEx (ignore case)</b> och lägg till detta RegEx-sträng <code>.pdf|.mp4|.mp3|.xlsx|.xls|.epub|.jpeg$</code>. Känn dig fri att lägga till andra filändelser som är relevanta för din webbplats och ta bort andra som inte är det. Avsluta med att klicka på <b>spara</b>.<br />

                <ImgScreenshot
                  src="gtm-setup/gtm-trigger-download-events.png"
                  alt="Triggerkonfiguration för nedladdningsspårning med en inbyggd variabel"
                  caption={`Triggerkonfiguration för nedladdningsspårning med inbyggd variabel.`}
                  className="article-img"
                />
              </li>
            </ul>
          </li>
          <li>
            <H as="h4">Spåra nedladdningar med en Google Analytics-händelse</H>
            <ul>
              <li>Lägg till en ny tagg av typen Universal Analytics, välj <b>event</b> som track-type och namnge den till exempel <em>Event - Downloads</em>.
              </li>
              <li><b>Kategorin</b> är strängen <code>Download</code>, <b>action</b> är strängen <code>click</code> och <b>label</b> är <code>{`{{ Click URL }}`}</code>.</li>
              <li><b>Non-interaction hit</b> är <code>false</code>, på grund av att interaktionen är ett aktivt engagemang.</li>
              <li>Och från de användardefinierade variablerna, använd samma <b>inställningsvariabel</b> som i andra spårningstaggar.<br />

                <ImgScreenshot
                  src="gtm-setup/google-analytics-event-tag-downloads.png"
                  alt="Händelsetagg för nedladdningsspårning"
                  caption={`Inställningar för händelsetaggen för nedladdningsspårning.`}
                  className="article-img"
                />
              </li>
              <li>Nu lägger du till en trigger till denna tagg genom att scrolla ner och välja <em>Event - Downloads</em>.</li>
              <li>Nästa steg, klicka på <b>spara</b>, gå till översikten och <b>submit</b> ändringarna.</li>
            </ul>
          </li>
        </ol>

        <H as="h2">Guide för att testa taggar och triggers</H>
        <p>En Google Tag Manager-tutorial skulle inte vara komplett utan en del om felsökning. För att testa någon av de tidigare händelsekonfigurationerna och vara säker på att de fungerar, gör något av följande:</p>
        <ol>
          <li><H as="h3">GTM Förhandsgranskningsläge</H>Först, låt oss starta förhandsgranskning och felsökningsläge. I Google Tag Manager-översikten, klicka på <b>preview</b> knappen i övre högra hörnet. Öppna sedan en ny
            flik <b>i samma webbläsare</b> och du kommer att märka att ett GTM-felsökningsfönster dyker upp längst ner.<br /><br />
            När du är i förhandsgranskningsläge, utför taggar och triggers själv. Klicka t.ex. på en extern länk eller ett telefonnummer och se om
            triggern och din tagg visas i GTM-felsökningsfönstret tillsammans med händelsedetaljerna du lade till.<br />

            <ImgScreenshot
              src="event-tracking/google-tag-manager-debugging-mode.png"
              alt="google tag manager felsökningsverktyg för händelser"
              className="article-img"
              caption={`Google Tag Manager felsökningsverktyg öppnas automatiskt när förhandsgranskningsläge aktiveras och visar data om taggar, triggers och variabler.`}
            />
          </li>
          <li><H as="h3">Google Analytics realtidsrapport för händelser</H>Ett annat sätt är att logga in på Google Analytics och sedan besöka <b>realtidsrapporten för händelser</b>. Händelserna bör visas några sekunder efter att triggern utlöstes.

            <ImgScreenshot
              src="event-tracking/google-analytics-event-tracking-realtime-report.png"
              alt="google analytics realtidsrapport för händelser"
              className="article-img"
              caption={`Du kan verifiera GTM-konfigurationer med Google Analytics realtidsrapport.`}
            />
          </li>
          <li><H as="h3">Chrome-tillägg för felsökning</H>Installera ett Chrome-tillägg för att felsöka taggen och triggern. Jag rekommenderar <a rel="noopener" target="_blank"
            href="https://chrome.google.com/webstore/detail/trackie/iphjjodolgbelaogcefgpegebgecopeh">Trackie</a> eller <a
              rel="noopener" target="_blank"
              href="https://chrome.google.com/webstore/detail/omnibug/bknpehncffejahipecakbfkomebjmokl">Omnibug</a>, men det finns också andra lösningar. När du har lagt till tillägget till Chrome kan du öppna <b>Chrome Developer Tools</b> och där kommer det att finnas en ny flik tillgänglig. Den visar all information om de utlösta reglerna för tagghantering. Om du sedan utlöser dina triggers i felsökningsläge kommer tillägget att visa trigger- och händelsedata.
            <br />
            <ImgScreenshot
              src="gtm-setup/test-gtm-trigger-and-tag-with-trackie.png"
              alt="Trackie Chrome-tillägg visar händelsedata."
              className="article-img"
              caption={`Trackie Chrome Extension visar händelsedata i Chrome Developer Tools.`}
            />
            <ImgScreenshot
              src="gtm-setup/test-gtm-trigger-and-tag-with-omnibug.png"
              alt="Omnibug Chrome Extension visar händelsedata för felsökning"
              className="article-img"
              caption={`Omnibug Chrome Extension visar händelsedata för felsökning i Chrome Dev Tools.`}
            />
          </li>
        </ol>

        <H as="h2">Ladda ner GTM konfigurationscontainerfil</H>
        <p>Eftersom de ovanstående konfigurationerna är allmänt användbara för de flesta Google Tag Manager-implementationer har jag skapat den ovanstående GTM-uppsättningen som en fil att importera till andra Google Tag Manager-containrar.<br />
          Det är en <code>.json</code> fil med inställningarna och namngivningskonventionen vi har gått igenom. Du kan bara importera containerkoden utan att behöva ställa in något manuellt.</p>
        <p>Antingen <b>använder du den med en helt ny container</b> för att spara tid vid inställningen av taggar, eller så kan du <b>importera dem till din befintliga container</b> och uppdatera Google Analytics inställningsvariabel inklusive spårnings-ID till ditt eget ID.</p>
        <p>Du kan ladda ner och installera dessa konfigurationer (var och en med taggar, triggers och variabler) för att ställa in Google Tag Manager:</p>
        <ul>
          <li>Spåra utgående länkar</li>
          <li>Spåra e-postklick</li>
          <li>Spåra telefonnummerklick</li>
          <li>Spåra nedladdningar</li>
        </ul>
        <p className="baseline">Importera helt enkelt containerinställningarna och distribuera dem. För demonstrationsändamål har jag lagt till en Google Analytics inställningsvariabel med ett Google Analytics spårnings-ID på <em>UA-12345678-9</em>.</p> 
        <p><b>Uppdatera GA-spårningskoden till din egen</b> eller alternativt, <b>ändra GA-inställningsvariabeln i taggkonfigurationen till din egen</b>.</p>
        <p>Ladda ner GTM-uppsättningskonfigurationen och se nedan hur du importerar den.</p>
        <a href="/downloads/best-practice-gtm-configs.json" download="best-practice-gtm-configs.json" ><CtaPrimary color="purple">Ladda ner GTM-uppsättning</CtaPrimary></a>
        <H as="h3">Importera inställningar till din GTM-container</H>
        <p>För att få ut det mesta av denna GTM-tutorial, följ stegen nedan och importera inställningarna till din GTM-container:</p>
        <ul>
          <li>gå till <b>admin</b> &gt; <b>import container</b>.</li>
          <li>välj JSON-filen du just laddade ner.</li>
          <li>välj ett <b>nytt arbetsutrymme</b> med namnet t.ex. <em>Import Bluerivermountains</em>.</li>
          <li>som <b>importalternativ</b> välj att <b>merge</b> och <b>rename</b> eventuella konfliktiga taggar, triggers och variabler.</li>
          <li>klicka på <b>confirm</b> och tryck på <b>submit</b> och <b>publish</b> knappen för att distribuera taggarna.<br /><br />Låt mig visa dig i denna video:

            <video
              loading="lazy"
              title={`Instruktionsvideo för att importera en Google Tag Manager Container`}
              autoplay
              loop
              controls
              css="max-width:100%;border:3px solid lightgrey;"
            >
              <source src="/video/import-gtm-container.mp4" type="video/mp4" />
            </video>
          </li>

          <li>Till sist, ändra GA-spårnings-ID i Google Analytics-inställningsvariabeln till ditt eget spårnings-ID eller uppdatera inställningsvariabeln i
            taggarna till din egen inställningsvariabel.</li>
        </ul>
        <H as="h2">Notering om personuppgifter</H>
        <p>Vi måste vara medvetna om informationen vi spårar. Data är inte bara data, eftersom länder har regler om datasekretess som påverkar vilken typ av information vi får samla in under spårning.</p>
        <p>På samma sätt finns det också villkor från Googles sida som förbjuder spårning av personuppgifter och att skicka data till deras servrar.</p>
        <p>Med det sagt:</p>
        <p>Generellt sett är <b>e-postadresser eller telefonnummer personligt identifierbar information (PII)</b> och vi får inte
          skicka det till vårt Google Analytics-konto eftersom det är <a rel="noopener" target="_blank" href="https://support.google.com/analytics/answer/2795983">mot deras användarvillkor</a>.</p>
        <p>Dock är telefonnumren på vår webbplats eller våra egna företags e-postadresser knappast privata eftersom det inte är användarnas
          data utan vår egen och offentligt tillgänglig på webbplatsen.<br />
          Ändå, om Google Analytics <em>någon gång</em> granskade deras databas och fann den informationen, kunde de inte veta
          att det faktiskt inte är PII-data.<br />
          Därför rekommenderar jag att inte ta några risker och <b>maskera alla e-postadresser och telefonnummer som skickas till
            Google Analytics-kontot</b>.</p>
        <p>Simo Ahava gjorde ett bra jobb och skrev ett <a rel="noopener" target="_blank"
          href="https://www.simoahava.com/gtm-tips/remove-pii-google-analytics-hits/">anpassat uppgiftskript för att ta bort PII
          från Google Analytics träffar</a> och jag rekommenderar att du implementerar detta tillsammans med de ovanstående konfigurationerna.<br />
          Det är inte ett måste, men genom att implementera det undviker du eventuell förvirring om du har PII-data eller inte.</p>
        <H as="h2">Vanliga frågor</H>
        <H as="h3">Behöver jag Google Tag Manager?</H>
        <p>Ja, eftersom din webbplats troligtvis vill köra Google Analytics och andra tredjepartsskript. Att ställa in allt
          det är mycket lättare och snabbare med Google Tag Manager. Plus att din webbplats <a rel="noopener" target="_blank"
            href="https://marketingland.com/audit-of-online-retailer-sites-shows-tag-management-systems-improve-load-times-reduce-errors-62173">laddar
            lite snabbare</a> också.</p>
        <H as="h3">När ska jag använda Google Tag Manager?</H>
        <p>Redan om du bara vill köra Google Analytics bör du använda Google Tag Manager. Att ställa in <Link to="/se/händelsespårning">händelsespårning</Link>, cross-domain spårning eller formulärspårning är vanliga nästa steg, men mycket <b>snabbare</b> och <b>enklare</b> med GTM än utan. Det finns inbyggda triggers för scroll-spårning och video-spårning också, plus många tutorials online som förklarar hur man <Link to="/se/google-analytics-setup">ställer in Google Analytics</Link> med det.</p>
        <H as="h3">Hur använder jag Google Tag Manager med Google Analytics?</H>
        <p>Logga in på <a href="https://analytics.google.com" rel="noopener" target="_blank">analytics.google.com</a> med ditt Google-konto och få din Google Analytics-spårningskod inklusive spårnings-ID. Nu, lägg inte till Google Analytics-taggen i källkoden på din webbplats. Den enda hårdkodade taggen ska vara Google Tag Manager-taggen. Gå till <a target="_blank" rel="noopener" href="https://tagmanager.google.com/">tagmanager.google.com</a> för att få GTM-kodsnutten och implementera den istället på varje sida av din webbplats. Slutligen, implementera GA-koden genom en inbyggd tagg, lägg till ditt spårnings-ID och fortsätt att <Link to="/se/google-analytics-setup">ställa in Google Analytics</Link> <b>genom Google Tag Manager</b>. För att lägga till marknadsföringstaggar eller konfigurera anpassade taggar använder du alltid GTM framöver.</p>
        <H as="h3">Vad är skillnaden mellan Google Analytics och Google Tag Manager?</H>
        <p>Google Analytics är biblioteket för att samla in data om besök och engagemang på din webbplats. Google Tag Manager å andra sidan är ett bibliotek som körs på din webbplats för att implementera andra bibliotek eller <em>spårningsverktyg</em> som Google Analytics. Eftersom många marknadsförings- och analysverktyg har extra JavaScript-snippets, använder du GTM-användargränssnittet för att ställa in dem alla.</p>
        <H as="h3">Var lägger jag Google Tag Manager-koden?</H>
        <p>Den <b>första</b> delen av koden går <em>så högt som möjligt</em> in i <code>&lt;head&gt;</code> sektionen. Jag
          rekommenderar att implementera den inom <code>&lt;head&gt;</code> men efter alla <code>&lt;style&gt;</code> eller <code>&lt;script&gt;</code> taggar som är viktiga för att rendera sidan - eftersom vi inte vill fördröja dem.<br />
          Den <b>andra</b> delen är för att möjliggöra en grundläggande funktionalitet i webbläsare med JavaScript avstängt. Det går <em>så högt som möjligt</em> in i <code>&lt;body&gt;</code> elementet.<br />
          Logiken bakom placeringen av båda taggarna är att säkerställa tidig laddning av GTM. Det gör att du kan utlösa anpassade taggar så tidigt som möjligt under sidladdningen.</p>

        <br />
        <ImgScreenshot
          src="google-analytics-setup/google-tag-manager-code-snippet.png"
          alt="Google Tag Manager kod"
          className="article-img"
          caption={`GTM-taggen har två delar. Den första placeras högt upp i head-taggen och den andra precis efter den öppnande body-taggen (se nedan).`}
        />

        <br />

        <ImgScreenshot
          src="data-layer/positionierung-data-layer-gtm.png"
          alt="positionering av data layer före tagghanteringstaggen i källkoden"
          className="article-img"
          caption={`Den övergripande ordningen ska alltid vara 1. data layer-objekt 2. Google Tag Manager, båda i <head> och 3. den andra GTM-koden högt upp i <body> taggen.`}
        />

        <H as="h3">Inkluderar Google Tag Manager Google Analytics?</H>
        <p>Nej, men Google Tag Manager gör det möjligt att implementera Google Analytics på några sekunder med bara några få klick. Det enda
          du behöver är ditt <Link to="/se/google-analytics-setup#where-to-get-the-google-analytics-tracking-code">Google Analytics-spårnings-ID</Link>. Generellt sett behöver du dock inte använda Google Analytics med Google Tag Manager.
          De är <b>oberoende</b> av varandra.</p>
        <H as="h3">Hur får jag tillgång till Google Tag Manager?</H>
        <p>Besök <a target="_blank" href="https://tagmanager.google.com/" rel="noopener">tagmanager.google.com</a> och logga in med ditt Google-konto för att få tillgång till Google Tag Manager. För att börja använda GTM, skapa ett nytt konto och välj webbplats som målplattform. Ta sedan kodsnutten och installera den på varje sida av din webbplats.</p>
        <Helmet>
          <script type="application/ld+json">{FAQs}</script>
        </Helmet>
      </MainContent>
    </React.Fragment>

  </Layout >
);

export default GTMsetup;
